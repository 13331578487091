@charset "utf-8";

// IBM Plex Mono
@font-face { font-family: 'IBM Plex Mono'; font-style: normal; font-weight: 100; src: url("/assets/fonts/IBMPlexMono-Thin.woff2") format("woff2"); }
@font-face { font-family: 'IBM Plex Mono'; font-style: normal; font-weight: 200; src: url("/assets/fonts/IBMPlexMono-ExtraLight.woff2") format("woff2"); }
@font-face { font-family: 'IBM Plex Mono'; font-style: normal; font-weight: 300; src: url("/assets/fonts/IBMPlexMono-Light.woff2") format("woff2"); }
@font-face { font-family: 'IBM Plex Mono'; font-style: normal; font-weight: 400; src: url("/assets/fonts/IBMPlexMono-Regular.woff2") format("woff2"); }
@font-face { font-family: 'IBM Plex Mono'; font-style: normal; font-weight: 500; src: url("/assets/fonts/IBMPlexMono-Medium.woff2") format("woff2"); }
@font-face { font-family: 'IBM Plex Mono'; font-style: normal; font-weight: 600; src: url("/assets/fonts/IBMPlexMono-SemiBold.woff2") format("woff2"); }
@font-face { font-family: 'IBM Plex Mono'; font-style: normal; font-weight: 700; src: url("/assets/fonts/IBMPlexMono-Bold.woff2") format("woff2"); }

body {
  font-family: IBM Plex Mono, monospace, system-ui, sans-serif;
  padding: 0;
  margin: 0;
  color: black;
  font-kerning: normal;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: subpixel-antialiased;
}

.root {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

h1 {
  font-size: 2.7rem;
  margin-bottom: 0em;
  font-weight: 740;
  letter-spacing: -0.055em;
}

h2 {
  font-size: 1em;
  font-weight: 400;
}

a {
  color: black;
  font-size: 1rem;
}

#container {
    position: relative;
}

#o {
    animation: bounce 0.9s infinite;
    display: inline-block;
    transform: scaleY(0.5) translateY(40%);
}

@keyframes bounce {
    50% {
        transform: scaleY(1) translateY(-120%);
    }
}

#shadow {
    background-color: rgba(0, 0, 0, 0.15);
    width: 100%;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    bottom: 3px;
    left: 0;
    z-index: -1;
    animation: shadow-hide 0.9s infinite;
    transform: scaleX(70%)
}

@media (prefers-color-scheme: dark) {
    body {
        background-color: #2B303B;
        color: #C0C5CE;
    }
    a {
        color: #C0C5CE;
    }
}
